import { Injectable, Injector } from '@angular/core';
import { combineLatest, firstValueFrom, map, shareReplay, tap } from 'rxjs';
import { Message } from '../../shared/models/message';
import { convertSnaps } from '../utils/db-utils';
import { FirestoreService } from './firestore.service';
import {
  Timestamp,
  serverTimestamp,
  query,
  where,
  collectionSnapshots,
  DocumentReference,
  DocumentData,
  getCountFromServer,
  writeBatch,
  refEqual,
} from '@angular/fire/firestore';
import { Customer } from 'src/app/shared/models/customer';
import { UserService } from './user.service';

export const MESSAGES_PATH: string = 'messages';
export const MESSAGE_ATTACHMENTS_PATH = 'messageAttachments';

@Injectable({
  providedIn: 'root',
})
export class MessageService extends FirestoreService<Message> {
  constructor(injector: Injector, private userSvc: UserService) {
    super(injector, MESSAGES_PATH);
  }

  async getUnreadMessageCountOfTrainerSingle(customer: Customer) {
    const customerUserRef = this.userSvc.getRef(customer.ref.id);
    const trainerUserRef = this.userSvc.getRef(customer.trainerRef.id);
    const messageCollectionRef = this.getCollection();
    const queryUnreadMessages = query(
      messageCollectionRef,
      where('from', '==', customerUserRef),
      where('to', '==', trainerUserRef),
      where('readAt', '==', null)
    );
    const res = await getCountFromServer(queryUnreadMessages);
    return res.data().count;
  }

  getUnreadMessageCountOfTrainerLive(customer: Customer) {
    const customerUserRef = this.userSvc.getRef(customer.ref.id);
    const trainerUserRef = this.userSvc.getRef(customer.trainerRef.id);
    const messageCollectionRef = this.getCollection();
    const queryUnreadMessages = query(
      messageCollectionRef,
      where('from', '==', customerUserRef),
      where('to', '==', trainerUserRef),
      where('readAt', '==', null)
    );
    return collectionSnapshots(queryUnreadMessages).pipe(
      map((unreadMessages) => unreadMessages.length)
    );
  }

  getMessagesForCustomerAndTrainer(
    fromUserRef: DocumentReference<DocumentData>,
    toUserRef: DocumentReference<DocumentData>
  ) {
    const messageCollectionRef = this.getCollection();

    const sentMessages$ = collectionSnapshots(
      query(
        messageCollectionRef,
        where('from', '==', fromUserRef),
        where('to', '==', toUserRef)
      )
    ).pipe(
      map((snaps) => convertSnaps<Message>(snaps)),
      shareReplay(1)
    );

    const receivedReadMessages$ = collectionSnapshots(
      query(
        messageCollectionRef,
        where('from', '==', toUserRef),
        where('to', '==', fromUserRef),
        where('readAt', '!=', null)
      )
    ).pipe(
      map((snaps) => convertSnaps<Message>(snaps)),
      shareReplay(1)
    );

    const receivedUnreadMessages$ = collectionSnapshots(
      query(
        messageCollectionRef,
        where('from', '==', toUserRef),
        where('to', '==', fromUserRef),
        where('readAt', '==', null)
      )
    ).pipe(
      map((snaps) => convertSnaps<Message>(snaps)),
      tap(async (unreadMessages) => {
        const currentUser = await firstValueFrom(this.userSvc.currentUser$);
        if (
          currentUser &&
          refEqual(fromUserRef, currentUser.ref) &&
          unreadMessages.length > 0
        ) {
          const batch = writeBatch(this.firestore);
          unreadMessages.map((unreadMessage) => {
            batch.update(unreadMessage.ref, {
              readAt: serverTimestamp(),
            });
          });
          batch.commit();
        }
      }),
      shareReplay(1)
    );

    return combineLatest([
      sentMessages$,
      receivedReadMessages$,
      receivedUnreadMessages$,
    ]).pipe(
      map((results) => {
        const [sentMessages, receivedReadMessages, receivedUnreadMessages] =
          results;
        const allMessages = [
          ...sentMessages,
          ...receivedReadMessages,
          ...receivedUnreadMessages,
        ].sort((a, b) => {
          if (a.createdAt && b.createdAt) {
            const date1 = a.createdAt;
            const date2 = b.createdAt;
            return date1.toMillis() - date2.toMillis();
          }
          return 0;
        });
        return allMessages;
      }),
      shareReplay(1)
    );
  }

  /* getMessagesForCustomerAndTrainer(
    fromUserRef: DocumentReference<DocumentData>,
    toUserRef: DocumentReference<DocumentData>
  ) {
    console.log('HI');
    const messageCollectionRef = this.getCollection();

    const sentMessages$ = collectionSnapshots(
      query(
        messageCollectionRef,
        where('from', '==', fromUserRef),
        where('to', '==', toUserRef)
      )
    ).pipe(
      map((snaps) => convertSnaps<Message>(snaps)),
      shareReplay(1)
    );

    const receivedMessages$ = collectionSnapshots(
      query(
        messageCollectionRef,
        where('from', '==', toUserRef),
        where('to', '==', fromUserRef)
      )
    ).pipe(
      map((snaps) => convertSnaps<Message>(snaps)),
      tap((messages) => {
        console.log(messages);
        const unreadMessages = messages.filter(
          (message) => message.readAt === null
        );
        console.log('Uread Messages', unreadMessages.length);
        console.log(unreadMessages);
        if (unreadMessages.length > 0) {
          const batch = writeBatch(this.firestore);
          unreadMessages.map((unreadMessage) => {
            //unreadMessage.readAt = serverTimestamp() as Timestamp;
            batch.update(unreadMessage.ref, {
              readAt: serverTimestamp(),
            });
          });
          batch.commit();
        }
        return messages;
      }),
      take(3),
      shareReplay(1)
    );
    return combineLatest([sentMessages$, receivedMessages$]).pipe(
      map((results) => {
        const sentMessages: Message[] = results[0];
        const receivedMessages: Message[] = results[1];
        const allMessages = [...sentMessages, ...receivedMessages].sort(
          (a, b) => {
            if (a.createdAt && b.createdAt) {
              const date1 = a.createdAt;
              const date2 = b.createdAt;
              return date1.toMillis() - date2.toMillis();
            }
            return 0;
          }
        );
        return allMessages;
      }),
      shareReplay(1)
    );
  } */

  createMessage(
    fromUserRef: DocumentReference<unknown>,
    toUserRef: DocumentReference<unknown>,
    message: string
  ) {
    const newMessage: Partial<Message> = {
      createdAt: serverTimestamp() as Timestamp,
      from: fromUserRef,
      message: message,
      to: toUserRef,
    };
    return this.add(newMessage);
  }
}
